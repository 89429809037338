@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('@/ui/theme/scrollbar.css');
@import url('@/ui/theme/tooltip.css');
@import url('@/ui/theme/reset.css');
@import url('@/ui/theme/slide.css');
@import url('@/ui/theme/sv-icon.css');
@import url('@/ui/theme/default.css');
@import url('@/ui/theme/datetime-picker.css');
@import url('@/ui/theme/custom-big-calendar-event.css');

@layer base {
  @font-face {
    font-family: 'SUITE';
    src: url('/fonts/SUITE.woff2') format('woff2');
    font-style: normal;
  }
  @font-face {
    font-family: 'Aptos';
    src: url('/fonts/Aptos.ttf') format('ttf');
    font-style: normal;
  }

  :root {
    --content-inversePrimary: theme(colors.content.inversePrimary);
  }
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
}
*,
:after,
:before {
  box-sizing: border-box;
  flex-shrink: 0;
}

html {
  height: 100%;
  width: 100%;
}

body {
  font-family:
    'SUITE',
    'Aptos',
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    system-ui,
    Roboto,
    'Helvetica Neue',
    'Segoe UI',
    'Apple SD Gothic Neo',
    'Noto Sans KR',
    'Malgun Gothic',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
  height: 100%;
  color: var(--content-inversePrimary);
}
